import * as React from "react";
import Layout from "../components/Layout";
import PageHeader from "../components/PageHeader";
import { Accordion } from '@mantine/core';

const FaqPage = () => {
  
    return (
      <Layout scrollNavbar={true}>
      <main className="py-20">
        <PageHeader title="FAQs" byline="Frequently Asked Questions" center={true}></PageHeader>
        <section className="max-w-4xl mx-auto pt-6">

        <Accordion multiple variant="filled" radius="lg" defaultValue={['item-1']} className="space-y-3" >
          <Accordion.Item value="item-1">
            <Accordion.Control><div className="text-xl">What is StepWise?</div></Accordion.Control>
            <Accordion.Panel>StepWise is a powerful and intuitive Open Source platform designed for creating and managing IT Ops processes, documentation, and workflows. It provides a user-friendly interface that allows organizations to streamline their operations, improve productivity, and ensure consistency in task execution.</Accordion.Panel>
          </Accordion.Item>
          <Accordion.Item value="customization">
            <Accordion.Control><div className="text-xl">Is StepWise a replacement to Confluence?</div></Accordion.Control>
            <Accordion.Panel>While Confluence is a highly capable documentation tool, it may not be specifically tailored to the needs of IT Ops. At StepWise, we believe that documentation is the fundamental starting point for process automation. Our platform offers a robust workflow engine that enables your documentation to evolve, eventually leading to hybrid or full autonomy within your IT operations.</Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item value="flexibility">
            <Accordion.Control><div className="text-xl">Can I use StepWise for Runbook Automation?</div></Accordion.Control>
            <Accordion.Panel>
              <p>Absolutely! StepWise was built at its core to follow the principles of runbook development and enhance them with features specifically designed to address the needs of today's modern IT Operations teams. By embracing runbook automation, StepWise offers numerous benefits such as:</p>
              <ul className="list-disc ml-6 my-3">
                <li>Increased operational efficiency</li>
                <li>Streamlined processes</li>
                <li>Reduced manual effort</li>
                <li>Enhanced consistency and standardization</li>
                <li>Faster incident resolution</li>
                <li>Better compliance and auditability</li>
                <li>Efficient knowledge retention</li>
              </ul> 
              <p>StepWise empowers IT Operations teams to optimize their workflows, leverage automation to save time and resources, and deliver exceptional results in a fast-paced IT environment.</p>  
            </Accordion.Panel>
          </Accordion.Item>

        </Accordion>
        </section>
      </main>

      </Layout>
    
    );
};

export default FaqPage;
